import React from "react";
import Link from "@mui/material/Link";
import styled from "@emotion/styled";

const LinkCustom = styled(Link)(({ theme }) => ({
  textDecoration: "none !important",
  "&:hover": {
    textDecoration: "none !important",
  },
}));
const LinkExternal = ({ to, children }) => {
  return (
    <LinkCustom href={to} target="_blank" rel="noreferrer" >
      {children}
    </LinkCustom>
  );
};

export default LinkExternal;
