import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import { theme } from "../../common/Theme/theme";
import LinkInternal from "./LinkInternal";
import LinkExternal from "./LinkExternal";

const Root = styled("div")({
  color: "#ffffff",
  // "&:hover": { color: "#d59570" },
});
const Container = styled("div")({
  zIndex: 10,
  border: "1px solid #d59570",
  position: "relative",
  width: "40vw",
  height: "40vw",
  margin: "5px auto",
  backgroundColor: "transparent",
  // [theme.breakpoints.up(425)]: {
  //   width: 185,
  //   height: 185,
  //   margin: "0 auto",
  // },
  // [theme.breakpoints.up("sm")]: {
  //   width: 250,
  //   height: 250,
  //   margin: "0 auto",
  // },

  [theme.breakpoints.up(600)]: {
    width: "clamp(20px,38vw,300px)",
    height: "clamp(20px,38vw,300px)",
  },
  [theme.breakpoints.up(1075)]: {
    width: 300,
    height: 300,
  },
});
const Cuadro = styled("div")({
  cursor: "pointer",
  zIndex: 30,
  position: "absolute",
  overflow: "hidden",
  width: "37vw",
  height: "37vw",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  backgroundColor: "transport",
  // [theme.breakpoints.up(425)]: {
  //   width: 175,
  //   height: 175,
  // },
  // [theme.breakpoints.up("sm")]: {
  //   width: 235,
  //   height: 235,
  // },
  [theme.breakpoints.up(600)]: {
    width: "clamp(20px,36vw,285px)",
    height: "clamp(20px,36vw,285px)",
  },
  [theme.breakpoints.up(1075)]: {
    width: 285,
    height: 285,
  },
});
const Zoom = styled("div")({
  zIndex: 20,
  transform: "scale(1.1)",
  width: "37vw",
  height: "37vw",
  transition: "all 1s ease 0s",
  // [theme.breakpoints.up(425)]: {
  //   width: 175,
  //   height: 175,
  // },
  // [theme.breakpoints.up("sm")]: {
  //   width: 240,
  //   height: 240,
  // },
  [theme.breakpoints.up(600)]: {
    width: "clamp(20px,36.5vw,290px)",
    height: "clamp(20px,36.5vw,290px)",
  },
  [theme.breakpoints.up(1075)]: {
    width: 290,
    height: 290,
  },
});
const Title = styled("h3")({
  fontFamily: "Dis-Italic",
  fontSize: 22,
  letterSpacing: "0.44px",
  lineHeight: "30px",
  textAlign: "center",
});

const ZoomInHome = ({ title, image, url, external }) => {
  const zoom = useRef(null);
  const zoomIn = () => {
    zoom.current.style.webkitTransform = "scale(1)";
  };
  const zoomOut = (posicion) => {
    zoom.current.style.webkitTransform = "scale(1.1)";
  };
  const [titleColor, setTitleColor] = useState("#ffffff");
  return (
    <Root>
      <Title style={{ color: titleColor, transition: "color .6s" }}>
        {title}
      </Title>
      <Container>
        <Cuadro onMouseEnter={zoomIn} onMouseLeave={zoomOut}>
          {external ? (
            <LinkExternal to={url} style={{ zIndex: 10 }}>
              <Zoom ref={zoom}>
                <img style={{ width: "100%" }} alt={"vino-url"} src={image} />
              </Zoom>
            </LinkExternal>
          ) : (
            <LinkInternal to={url} style={{ zIndex: 10 }}>
              <Zoom
                onMouseEnter={() => setTitleColor("#d59570")}
                onMouseLeave={() => setTitleColor("#ffffff")}
                ref={zoom}
              >
                <img style={{ width: "100%" }} alt={"vino-url"} src={image} />
              </Zoom>
            </LinkInternal>
          )}
        </Cuadro>
      </Container>
    </Root>
  );
};

export default ZoomInHome;
