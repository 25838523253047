import React, { useRef, useLayoutEffect } from "react";
// import { makeStyles } from "@mui/material/styles";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Link from "@mui/material/Link";
import { Button, Fade, Popper } from "@mui/material";
import styled from "@emotion/styled";
import { theme } from "../Theme/theme";
import { menu2 } from "../Arrays";

const ButtonText = styled(Button)({
  textShadow: "-1px 1px 0 black",
  fontSize: 13,
  color: "white",
  padding: "5px",
  fontFamily: "Rel-Bold",
  cursor: "pointer",
  letterSpacing: 2,

  [theme.breakpoints.up("lg")]: {
    padding: 5,
    paddingLeft: 5,
    fontSize: 13,
    margin: "0 10px",
  },
  [theme.breakpoints.up("xl")]: {
    margin: "0 14px",
  },
});

const SubmenuBox = styled("div")({
  display: "flex",
  flexDirection: "column",
  textTransform: "uppercase",
  backgroundColor: "rgba(39, 39, 36, .55)",
  marginTop: "5px",
  color: "white",
});
const LinkMenu = styled(Link)({
  textDecoration: "none",
  cursor: "pointer",
  fontFamily: "Rel-Regular",
  fontSize: "13px",
  padding: "11px 20px",
  color: "inherit",
  letterSpacing: "2.4px",
  opacity: 0.85,

  "&:hover": {
    backgroundColor: "rgba(10, 10, 10, .85)",
    color: "#d59570",
  },
});

const ButtonHover = ({ url, nombre, index, submenu }) => {
  const [validate, setValidate] = React.useState(false);
  const location = useLocation();
  useLayoutEffect(() => {
    updateCurrentPath(); // eslint-disable-next-line
  }, [location]);

  const updateCurrentPath = () => {
    if (url === location.pathname) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  };
  const ButtonLink = styled(Link)({
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      ">hr": {
        width: "80%",
        visibility: "visible",
      },
    },
    "& hr": {
      width: validate ? "80%" : "11%",
      visibility: validate ? "visible" : "hidden",
    },
  });
  const ButtonHr = styled("hr")({
    margin: "0 auto",
    visibility: "hidden",
    border: "none",
    height: "4.5px",
    background: "#d59570",
    width: "11%",
    MozTransition: "all 0.2s ease 0s",
    OTransition: "all 0.2s ease 0s",
    msTransition: "all 0.2s ease 0s",
    WebkitTransition: "all 0.2s ease 0s",
  });

  // const classes = useStyles();
  const hr = useRef(null);
  const button = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? `transition-popper${index}` : undefined;

  return (
    <div
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      onClick={handleClose}
    >
      <ButtonLink
        // className={classes.link}
        to={url}
        component={RouterLink}
        aria-describedby={id}
      >
        <ButtonText
          disableRipple={true}
          disableFocusRipple
          type="button"
          // className={classes.texto}
          ref={button}
        >
          {nombre}
        </ButtonText>
        <ButtonHr
          ref={hr}
          // className={classes.hr}
        />
      </ButtonLink>
      {submenu && (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition
          style={{
            zIndex: 10000,
          }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <SubmenuBox style={{ marginTop: "8px" }}>
                {menu2.map((el, index) => (
                  <LinkMenu key={index} to={el.link} component={RouterLink}>
                    {el.name}
                  </LinkMenu>
                ))}
              </SubmenuBox>
            </Fade>
          )}
        </Popper>
      )}
    </div>
  );
};

export default ButtonHover;
