import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./i18n";
import loadable from "@loadable/component";
import NoMatch from "./common/NoMatch";
import Header from "./common/Layout/Header";
import Footer from "./common/Layout/Footer";
// import Legal from "./views/Legal";
import { ParallaxProvider } from "react-scroll-parallax";
import { HelmetProvider } from "react-helmet-async";
import Wines from "./views/Wines/Wines";
import ContextWines from "./common/context/ContextWines";
import Legal from "./views/legales/Home";
const Home = loadable(() => import("./views/Home/Home"));
const HomeVarietals = loadable(() =>
  import("./views/la-mascota-varietals/Home")
);
const HomeMagnanime = loadable(() => import("./views/magnanime/Home"));
const HomeGranMascota = loadable(() => import("./views/gran-mascota/Home"));
const HomeSignature = loadable(() => import("./views/signature/Home"));
const HomeChardonnay = loadable(() => import("./views/chardonnay/Home"));
const HomePinotNoir = loadable(() => import("./views/pinot-noir/Home"));
const HomeMalbec = loadable(() => import("./views/malbec/Home"));
const HomeGranVinoTinto = loadable(() =>
  import("./views/gran-vino-tinto/Home")
);
const HomeVarietalsLaMascota = loadable(() =>
  import("./views/generic-mascota-wines/Home")
);

const HomeView13 = loadable(() => import("./views/unanime-varietals/Home"));
const HomeView20 = loadable(() => import("./views/View20/Home"));
const HomeView5 = loadable(() => import("./views/winemaker/Home"));
const FindUs = loadable(() => import("./views/vineyards/Home"));

function App() {
  return (
    <Suspense fallback={<div>...</div>}>
      <HelmetProvider>
        <ParallaxProvider>
          <Router>
            <Routes>
              <Route path="*" element={<Legal />} />
            </Routes>
            <Routes>
              <Route path="*" element={<Header />} />
            </Routes>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/wines" element={<Wines />} />
              <Route
                path="/wines/la-mascota/:id"
                element={
                  <ContextWines>
                    <HomeVarietalsLaMascota />
                  </ContextWines>
                }
              />

              <Route path="/la-mascota/varietals" element={<HomeVarietals />} />
              <Route path="/wines/gran_mascota" element={<HomeGranMascota />} />
              <Route path="/wines/magnanime" element={<HomeMagnanime />} />
              <Route
                path="/wines/unanime_signature"
                element={<HomeSignature />}
              />
              <Route
                path="/wines/unanime/chardonnay"
                element={<HomeChardonnay />}
              />
              <Route
                path="/wines/unanime/pinot_noir"
                element={<HomePinotNoir />}
              />
              <Route path="/wines/unanime/malbec" element={<HomeMalbec />} />
              <Route
                path="/wines/unanime/gran_vino_tinto"
                element={<HomeGranVinoTinto />}
              />
              <Route path="/unanime/varietals" element={<HomeView13 />} />
              <Route path="/find_us" element={<HomeView20 />} />
              <Route path="/winemaker" element={<HomeView5 />} />
              <Route path="/vineyards" element={<FindUs />} />
              {/* <Route path="/legales" element={<Legales />} /> */}
              <Route path="*" element={<NoMatch />} />
            </Routes>
            <Routes>
              <Route path="*" element={<Footer />} />
            </Routes>
          </Router>
        </ParallaxProvider>
      </HelmetProvider>
    </Suspense>
  );
}

export default App;
