import React from "react";
import { Navigate } from "react-router-dom";
const NoMatch = () => {
  return (
    <Navigate
      to="/"
      // state={{ from: location }} replace
    />
  );
};

export default NoMatch;
