import React, { createContext, useState } from "react";
// import { useTranslation } from "react-i18next";
// import i18next from "i18next";

import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {
  Button,
  //  Typography
} from "@mui/material";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";

import LinkInternal from "../components/LinkInternal";
import logo from "../../assets/icons/logo_m_mascota.png";
import iconClose from "../../assets/icons/menu_cruz.svg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export const DrawerContextMobile = createContext(null);

// const Language = () => {
//   const { t } = useTranslation();

//   async function handleClick(lang) {
//     i18next.changeLanguage(lang);
//   }
//   const ButtonLang = styled(Button)(({ theme }) => ({
//     padding: 0,
//     justifyContent: "flex-start",
//   }));
//   return (
//     <ButtonLang
//       onClick={() => handleClick(t("language") === "eng" ? "esp" : "eng")}
//     >
//       <Typography variant="header">
//         {t("language") === "eng" ? "Español" : "English"}
//       </Typography>
//     </ButtonLang>
//   );
// };
const CustomDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "#272724",
    height: "100vh",
    width: "100%",
    // maxWidth: "240px",
    overflow: "scroll",
    opacity: "98%",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 5000,

    // transitionDuration: "1s !important",
  },
  "& .MuiDrawer-paperAnchorTop": {
    top: 0,
    height: "100vh",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
}));

const Lists = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
});

const ToolbarCustom = styled(Toolbar)(({ theme }) => ({
  padding: "6px",
}));

const ImageLogo = styled("img")(({ theme }) => ({
  height: 50,
}));
const ButtonDrawer = styled(Button)(({ theme }) => ({
  position: "absolute",
  padding: 0,
  right: 10,
}));
const ImageIcon = styled("img")(({ theme }) => ({
  height: 50,
}));
const ContainerMenu = styled("div")(({ theme }) => ({
  overflow: "scroll",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {},
}));

const LinkItem = styled("p")(({ theme }) => ({
  color: "#FFFFFF",
  margin: 0,
  padding: "18px 21px",
  textDecoration: "none",
  fontSize: "15px",
  letterSpacing: "1.7px",
  textTransform: "uppercase",
  textAlign: "left",
  cursor: "pointer",
  fontFamily: "Rel-Bold",
}));

const LinkSubItem = styled(LinkItem)(({ theme }) => ({
  fontSize: "15px",
  color: "#ffffff",
  fontFamily: "Rel-Regular",
  padding: "6px 21px",
}));
const Languages = styled("div")({
  position: "absolute",
  display: "flex",
  letterSpacing: "1.7px",
  fontFamily: "Rel-Medium",
  fontSize: "15px",
  gap: "5px",
  paddingLeft: "8px",
});
const LanguageItems = styled("p")({
  margin: "0",
  color: "#ffffff",
  padding: "4px",
  cursor: "pointer",
});

const DrawerMenu = ({ openDrawer, handleDrawerClose }) => {
  const { t } = useTranslation();
  const [eng, setEng] = useState(false);
  async function handleClick(lang) {
    i18next.changeLanguage(lang);
  }
  const menu2 = [
    {
      link: "/la-mascota/varietals",
      name: "La Mascota",
    },
    {
      link: "/unanime/varietals",
      name: "unánime",
    },
    {
      link: "/wines/unanime_signature",
      name: "unánime signature",
    },
    {
      link: "/wines/gran_mascota",
      name: "gran mascota",
    },
    {
      link: "/wines/magnanime",
      name: "magnánime",
    },
    {
      link: "/wines/la-mascota/sparkling",
      name: "sparkling",
    },
  ];

  const menu = [
    {
      link: "/wines",
      name: t("header.wines"),
      submenu: menu2,
    },
    {
      link: "/winemaker",
      name: t("header.winemaker"),
      submenu: null,
    },
    {
      link: "/vineyards",
      name: t("header.vineyards"),
      submenu: null,
    },
    {
      link: "/find_us",
      name: t("header.find-us"),
      submenu: null,
    },
  ];
  return (
    <DrawerContextMobile.Provider
      value={{ handleDrawerClose: handleDrawerClose }}
    >
      <CustomDrawer
        variant="persistent"
        anchor="right"
        open={openDrawer}
        onOpen={() => console.log("open")}
        onClose={handleDrawerClose}
      >
        <ToolbarCustom>
          <Languages>
            {!eng && (
              <LanguageItems
                onClick={() => {
                  setEng(true);
                  handleClick("en");
                }}
              >
                ENG
              </LanguageItems>
            )}
            {eng && (
              <LanguageItems
                onClick={() => {
                  setEng(false);
                  handleClick("es");
                }}
              >
                ESP
              </LanguageItems>
            )}
          </Languages>
          <Grid container alignItems="center" justifyContent={"center"}>
            <ButtonDrawer onClick={handleDrawerClose}>
              <ImageIcon src={iconClose} alt="close button" />
            </ButtonDrawer>

            <LinkInternal to={"/"}>
              <ImageLogo
                src={logo}
                alt={"logo-mascotas"}
                onClick={handleDrawerClose}
              />
            </LinkInternal>
          </Grid>
        </ToolbarCustom>
        <ContainerMenu>
          <Lists>
            {menu.map((item, index) => (
              <React.Fragment key={index}>
                <LinkInternal
                  to={item.link}
                  children={
                    <LinkItem
                      onClick={handleDrawerClose}
                      children={item.name}
                    ></LinkItem>
                  }
                />
                {item.submenu &&
                  item.submenu.map((item, i) => (
                    <LinkInternal
                      key={i + 1 + index}
                      to={item.link}
                      onClick={handleDrawerClose}
                      children={
                        <LinkSubItem onClick={handleDrawerClose}>
                          {item.name}
                        </LinkSubItem>
                      }
                    />
                  ))}
              </React.Fragment>
            ))}
          </Lists>
        </ContainerMenu>
      </CustomDrawer>
    </DrawerContextMobile.Provider>
  );
};

export default DrawerMenu;
