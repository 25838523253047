import React from "react";
import { useMediaQuery } from "@mui/material";
//elements
import { useParallax } from "react-scroll-parallax";
import styled from "@emotion/styled";
import { theme } from "../../common/Theme/theme";

const ContainerGeneral = styled("div")({
  position: "relative",
  width: "100%",
  // overflow: "hidden",
});

const ContainerBanner = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100vh",
  minHeight: "550px",
  position: "relative",
  overflow: "hidden",
});

const ContainerImage = styled("div")({
  position: "absolute",
  zIndex: 0,
  height: "100vh",
  width: "100%",
  minHeight: "550px",
  [theme.breakpoints.up("md")]: {},
});

// const Image = styled("img")({
//   minHeight: "100vh",
//   top: "50%",
//   left: "50%",
//   position: "absolute",
//   transform: "translate(-50%,-50%)",
//   minWidth: "100%",
//   // [theme.breakpoints.up(650)]: {
//   //   width: "100%",
//   //   minHeight: "auto",
//   // },
//   [theme.breakpoints.up(960)]: {
//     width: "auto",
//     minHeight: "100vh",
//   },
//   [theme.breakpoints.up(1560)]: {
//     top: "50%",
//     left: "50%",
//     position: "absolute",
//     transform: "translate(-50%,-50%)",
//     minHeight: "100vh",
//   },
//   [theme.breakpoints.up(1700)]: {
//     width: "100%",
//     height: "auto",
//   },
// });

const Backdrop = styled("div")({
  position: "absolute",
  width: "100%",
  height: "calc(100% + 15px)",
  top: -15,
  opacity: 0.4,
  backgroundColor: theme.palette.black,
  left: 0,
  zIndex: 30,
});

const ParallaxSection = ({
  children,
  urlBackgroundImgDesktop,
  urlBackgroundImgMobile,
  contentAling,
  imageAlign = "center",
  notHome = false,
}) => {
  const matchesHeader = useMediaQuery("(min-width:600px)");
  const parallax = useParallax({
    speed: -30,
  });
  const ContainerText = styled("div")({
    display: "flex",
    position: "relative",
    margin: "auto",
    maxWidth: 1300,
    padding: "45px 0px",
    width: "100%",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: contentAling,
    },
  });
  const Image = styled("img")({
    backgroundColor: "#272724",
    minHeight: "100vh",
    top: "50%",
    left: imageAlign === "center" ? "50%" : "right" ? "auto" : "0",
    right: imageAlign === "right" ? "0" : "auto",
    position: "absolute",
    transform: `translate( ${imageAlign === "center" ? "-50%" : "0"},-50%)`,
    minWidth: "100%",
    // [theme.breakpoints.up(650)]: {
    //   width: "100%",
    //   minHeight: "auto",
    // },
    [theme.breakpoints.up(960)]: {
      width: "auto",
      minHeight: "100vh",
    },
    [theme.breakpoints.up(1560)]: {
      top: "50%",
      left: "50%",
      position: "absolute",
      transform: "translate(-50%,-50%)",
      minHeight: "100vh",
    },
    // [theme.breakpoints.up(1700)]: {
    //   maxWidth: "100%",
    //   height: "auto",
    // },
  });

  return (
    <ContainerGeneral>
      <ContainerBanner
        style={{
          height: `${
            matchesHeader ? "calc(100vh - 109px)" : "calc(100vh - 51px)"
          }`,
        }}
      >
        <ContainerImage ref={parallax.ref}>
          <picture>
            <source srcSet={urlBackgroundImgMobile} media="(max-width:690px)" />
            <source
              srcSet={urlBackgroundImgDesktop}
              media="(min-width:691px)"
            />
            <Image src={urlBackgroundImgDesktop} alt="banner" />
          </picture>
        </ContainerImage>
        <ContainerText>{children && children}</ContainerText>
        {children && <Backdrop />}
      </ContainerBanner>
    </ContainerGeneral>
  );
};

export default ParallaxSection;
