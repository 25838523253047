import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Divider, Button } from "@mui/material";
import LinkInternal from "../components/LinkInternal";
import LinkExternal from "../components/LinkExternal";
import styled from "@emotion/styled";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";

const GridRoot = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  backgroundColor: "#272724",
  overflow: "hidden",
  padding: "5px 10px",
  paddingBottom: 30,
  paddingTop: 40,
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    padding: "30px 10px",
    paddingTop: 50,
  },
}));

const ContainerWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

const ContainerMascota = styled(Grid)(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.up("lg")]: {
    textAlign: "initial",
  },
}));

const ContainerUnanime = styled(Grid)(({ theme }) => ({
  justifyContent: "center",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.up("lg")]: {
    display: "flex",
    alignItems: "center",
  },
}));

const ButtonTextUnanime = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  padding: "6px 8px",
  display: "block",
  color: "#FFFFFF",
  fontSize: 11,
  fontFamily: "Rel-Bold",
  minHeight: 48,
  [theme.breakpoints.up("sm")]: {
    "&:hover": {
      color: "#d59570",
    },
  },
  [theme.breakpoints.up("md")]: {
    fontSize: 11,
    // padding: "6px 8px",
  },
}));

const DividerCustom = styled(Divider)(({ theme }) => ({
  width: "100%",
  color: "#d59570",
  backgroundColor: "#d59570",
  margin: "10px auto",
  [theme.breakpoints.up("md")]: {
    margin: "30px auto",
  },
}));

const TypographyCondicion = styled(Typography)(({ theme }) => ({
  display: "block",
  color: "#FFFFFF",
  fontSize: 10,
  fontFamily: "Rel-Regular",
  [theme.breakpoints.up("md")]: {
    fontSize: 10,
  },
}));
const TypographyFollow = styled(Typography)(({ theme }) => ({
  textTransform: "uppercase",
  color: "#d59570",
  fontSize: 11,
  fontFamily: "Rel-Bold",
}));
const ContainerFollow = styled("div")(({ theme }) => ({
  textAlign: "center",
  width: 140,
  height: "100%",
  margin: "0 auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.up("sm")]: {
    // width: 130,
    // display: "block",
  },
  [theme.breakpoints.up("lg")]: {
    // textAlign: "initial",
    // display:"grid",
    // alignItems:"flex-end"
  },
}));
// const useStyles = makeStyles((theme) => ({

//   logoMascota: {
//     width: 70,
//     [theme.breakpoints.up("sm")]: {
//       width: 100,
//     },
//   },
//   logoUnanime: {
//     width: 110,
//     [theme.breakpoints.up("lg")]: {
//       width: 140,
//     },
//   },
//   redes: {
//     position: "relative",
//     textAlign: "center",
//     justifyContent: "center",
//     [theme.breakpoints.up("sm")]: {
//       position: "absolute",
//       right: 0,
//       top: 10,
//       marginRight: -40,
//     },
//     [theme.breakpoints.up("md")]: {
//       marginRight: 0,
//     },
//   },
//   containerMascota: {

//   },
//   containerUnanime: {
//     textAlign: "center",
//     display: "grid",
//     alignItems: "flex-end",
//     [theme.breakpoints.up("lg")]: {
//       display: "grid",
//       alignItems: "flex-end",
//     },
//   },
//   containerRedes: {
//     textAlign: "center",
//     display: "grid",
//     alignItems: "flex-end",
//     [theme.breakpoints.up("lg")]: {
//       justifyContent: "flex-end",
//     },
//   },
//   containerFollow: {

//   },

//   textFollow: {
//     color: "#d59570",
//     fontSize: 14,
//     fontFamily: "Rel-Bold",
//   },
//   textCondition: {
//     display: "block",
//     color: "#FFFFFF",
//     fontSize: 12,
//     fontFamily: "Rel-Regular",
//     [theme.breakpoints.up("md")]: {
//       fontSize: 14,
//     },
//   },
//   divider: {
//     width: "100%",
//     color: "#d59570",
//     backgroundColor: "#d59570",
//     margin: "10px auto",
//     [theme.breakpoints.up("md")]: {
//       margin: "30px auto",
//     },
//   },
//   arrow: {
//     [theme.breakpoints.down("sm")]: {
//       paddingRight: 0,
//     },
//   },
//   link: {
//     textDecoration: "none",
//     "&:hover": {
//       textDecoration: "none",
//     },
//   },
// }));

const Footer = (props) => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:800px)");
  return (
    <GridRoot container direction="row" justify="center" alignItems="center">
      <Grid
        item
        xs={12}
        style={{
          width: "100%",
          top: 0,
          position: "absolute",
          textAlign: "center",
          background:
            "linear-gradient(to right, rgba(198,172,94,1) 0%, rgba(198,172,94,1) 29%, rgba(254,246,231,1) 56%, rgba(213,186,147,1) 77%, rgba(146,93,29,1) 100%)",
          height: 10,
        }}
      />
      <Grid
        container
        style={{ maxWidth: 900, paddingLeft: "10px", paddingRight: "10px" }}
      >
        <ContainerWrapper>
          <ContainerMascota item xs={12} sm={3} md={3} lg={3}>
            <LinkInternal to={"/"}>
              <Button>
                <img
                  src={require("../../assets/images/header/logo_mascota.png")}
                  alt="imagen home"
                  height={matches ? "71.81px" : "50.267px"}
                  width={matches ? "103.76px" : "72.632px"}
                />
              </Button>
            </LinkInternal>
          </ContainerMascota>
          <ContainerUnanime item xs={12} sm={8} md={6} lg={6}>
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LinkInternal to={"/wines"}>
                <ButtonTextUnanime style={{ letterSpacing: "1.1px" }}>
                  {t("header.wines")}
                </ButtonTextUnanime>
              </LinkInternal>
              <Typography
                style={{
                  color: "#FFFFFF",
                  fontSize: 11,
                  letterSpacing: "1.1px",
                  padding: "6px 3px 6px 0px",
                  fontFamily: "Rel-Bold",
                  fontWeight: "inherit",
                }}
              >
                |
              </Typography>
              <LinkInternal to={"/winemaker"}>
                <ButtonTextUnanime style={{ letterSpacing: "1.1px" }}>
                  {t("header.winemaker")}
                </ButtonTextUnanime>
              </LinkInternal>
              <Typography
                style={{
                  color: "#FFFFFF",
                  fontSize: 11,
                  letterSpacing: "1.1px",
                  padding: "6px 0px 6px 3px",
                  fontFamily: "Rel-Bold",
                  fontWeight: "inherit",
                }}
              >
                |
              </Typography>
              <LinkInternal to={"/vineyards"}>
                <ButtonTextUnanime style={{ letterSpacing: "1.1px" }}>
                  {t("header.vineyards")}
                </ButtonTextUnanime>
              </LinkInternal>
              <Typography
                style={{
                  color: "#FFFFFF",
                  fontSize: 11,
                  letterSpacing: "1.1px",
                  padding: "6px 1px",
                  fontFamily: "Rel-Bold",
                  fontWeight: "inherit",
                }}
              >
                |
              </Typography>
              <LinkInternal to={"/find_us"}>
                <ButtonTextUnanime style={{ letterSpacing: "1.1px" }}>
                  {t("header.find-us")}
                </ButtonTextUnanime>
              </LinkInternal>
              {/* <Typography
              style={{
                color: "#FFFFFF",
                fontSize: 11,
                padding: "6px 1px",
                fontFamily: "Rel-Bold",
              }}
            >
              |
            </Typography> */}
              {/* <LinkInternal to={"/find_us"}>
              <ButtonTextUnanime>SCORES</ButtonTextUnanime>
            </LinkInternal> */}
            </div>
          </ContainerUnanime>

          <Grid item xs={12} sm={4} md={3} lg={3}>
            <ContainerFollow>
              <TypographyFollow style={{ letterSpacing: "1.1px" }}>
                {t("follow-us")}
              </TypographyFollow>
              <LinkExternal to="https://www.instagram.com/mascotavineyards/?igshid=qkrm1xpz2usm">
                <img
                  src={require("../../assets/images/footer/instagram.png")}
                  alt="instagram"
                  height="24.92px"
                  widht="24.92px"
                />
              </LinkExternal>
            </ContainerFollow>
          </Grid>
        </ContainerWrapper>
        <DividerCustom />
      </Grid>

      <Grid item xs={12} style={{ textAlign: "center" }}>
        <TypographyCondicion>{t("copyright")}</TypographyCondicion>
      </Grid>
    </GridRoot>
  );
};

export default Footer;
