import React from "react";
import ButtonHover from "../components/ButtonHover";
import LinkInternal from "../components/LinkInternal";
import styled from "@emotion/styled";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
import DrawerMenu from "./DrawerMenu";
import { menu2 } from "../Arrays";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Header = (props) => {
  async function handleClick(lang) {
    i18next.changeLanguage(lang);
  }
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:700px)");

  const menu = [
    {
      link: "/wines",
      name: t("header.wines"),
      submenu: menu2,
    },
    {
      link: "/winemaker",
      name: t("header.winemaker"),
      submenu: null,
    },
    {
      link: "/vineyards",
      name: t("header.vineyards"),
      submenu: null,
    },
    t("language") === "esp"
      ? {
          link: "/find_us",
          name: t("header.find-us"),
          submenu: null,
        }
      : null,
  ];
  const AppBar = styled("header")({
    boxSizing: "border-box",
    position: "sticky",
    top: 0,
    padding: "0 63px 0 50px",
    width: "100%",
    backgroundColor: "#272724",
    zIndex: 3000,
  });

  const ToolBar = styled("div")(({ theme }) => ({
    boxSizing: "border-box",
    width: "100%",
    height: "109px",
    margin: "0 auto",
    maxWidth: "1366px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down(700)]: {
      height: "51px",
    },
  }));
  const NavContainer = styled("div")({
    display: "flex",
    gap: "15px",
    alignItems: "center",
  });
  const Languages = styled("div")({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "-4.4px",
  });
  const HeaderNav = styled("nav")({
    display: "flex",
    alignItems: "center",
  });
  const LanguageItems = styled("p")({
    margin: 0,
    padding: "5px",
    fontSize: "12px",
    fontFamily: "Rel-Regular",
    color: "#FFFFFF",
    cursor: "pointer",
    transition: "color .5s",
    "&:hover": {
      color: "#d59570",
    },
  });

  const [openDrawer, setOpenDrawer] = useState(false);
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <React.Fragment>
      <AppBar>
        <ToolBar>
          <LinkInternal to={"/"}>
            {matches ? (
              <img
                width={"100px"}
                height={"70.58px"}
                src={require("../../assets/images/header/logo_mascota.png")}
                alt="logo mascota"
              />
            ) : (
              <img
                width={"50px"}
                height={"50px"}
                src={require("../../assets/icons/logo_m_mascota.png")}
                alt="logo mascota"
              />
            )}
          </LinkInternal>
          {matches ? (
            <NavContainer>
              <HeaderNav>
                {menu.map((el, index) => (
                  <React.Fragment>
                    {el && (
                      <ButtonHover
                        key={index}
                        url={el.link}
                        nombre={el.name.toLocaleUpperCase()}
                        submenu={el.submenu}
                      ></ButtonHover>
                    )}
                  </React.Fragment>
                ))}
              </HeaderNav>
              <Languages>
                <LanguageItems onClick={() => handleClick("en")}>
                  ENG
                </LanguageItems>
                <LanguageItems
                  style={{ marginTop: "-2px", padding: 0, fontSize: "18px" }}
                >
                  |
                </LanguageItems>
                <LanguageItems onClick={() => handleClick("es")}>
                  ESP
                </LanguageItems>
              </Languages>
            </NavContainer>
          ) : (
            <img
              src={require("../../assets/icons/menu_hamburguesa.svg").default}
              alt="hamburger logo"
              onClick={() => setOpenDrawer(true)}
              style={{ cursor: "pointer", height: "40px", width: "40px" }}
            />
          )}
        </ToolBar>
      </AppBar>
      <DrawerMenu
        openDrawer={openDrawer}
        handleDrawerClose={handleDrawerClose}
      />
    </React.Fragment>
  );
};

export default Header;
