import React, { createContext, useState } from "react";
import { useTranslation } from "react-i18next";
export const ContextWinesGlobal = createContext("");

const ContextWines = ({ children }) => {
  const { t } = useTranslation();
  const [wine, setWine] = useState({});
  const allWines = [
    {
      name: "Cabernet Sauvignon",
      id: "cabernet-sauvignon",
      position: 0,
      type: "mascota",
      title: "La Mascota",
      titleColor: "#D59570",
      bgCard: `url(${require("../../assets/images/vista-generica-vinos/bg-card-cabernet-sauvignon.jpg")})`,
      colorCardText: "#BABABA",
      bgColor: "#B98D67",
      borderColor: "#E0D1C4",
      bgImage: {
        url: `url(${require("../../assets/images/vista-generica-vinos/bg-img-cabernet-sauvignon.png")})`,
        size: "741.97px 477.86px",
        position: "top",
      },
      bottleshotUrl: require("../../assets/images/vista-generica-vinos/lm_cabernet_s.png"),
      bannerUrl: {
        desktop: require("../../assets/images/vista-generica-vinos/banner-cabernet-sauvignon.jpg"),
        mobile: require("../../assets/images/vista-generica-vinos/banner-cabernet-sauvignon.jpg"),
      },
      composition: "100% Cabernet Sauvignon",
      tastingNotes: t("wine-card.cabernet-sauvignon.tasting-notes-text"),
      winePts: true,
      apellation: true,
      dots: require("../../assets/images/vineyards/puntitos.png"),
      parallax: true,
    },

    {
      name: "Malbec",
      id: "malbec",
      position: 1,
      type: "mascota",
      title: "La Mascota",
      titleColor: "#B98D67",
      bgCard: `url(${require("../../assets/images/vista-generica-vinos/bg-card-malbec.jpg")})`,
      colorCardText: "#BABABA",
      bgColor: "#B98D67",
      borderColor: "#707070",
      bgImage: {
        url: `url(${require("../../assets/images/vista-generica-vinos/bg-img-cabernet-sauvignon.png")})`,
        size: "741.97px 477.86px",
        position: "top",
      },
      bottleshotUrl: require("../../assets/images/vista-generica-vinos/lm_malbec.png"),
      bannerUrl: {
        desktop: require("../../assets/images/vista-generica-vinos/banner-malbec.jpg"),
        mobile: require("../../assets/images/vista-generica-vinos/banner-malbec.jpg"),
      },
      composition: "100% Malbec",
      tastingNotes: t("wine-card.malbec.tasting-notes-text"),
      apellation: true,
      dots: require("../../assets/images/vista-generica-vinos/puntitos-light.png"),
      parallax: true,
    },

    {
      name: "Shiraz",
      id: "shiraz",
      position: 3,
      type: "mascota",
      title: "La Mascota",
      titleColor: "#D59570",
      bgCard: `url(${require("../../assets/images/vista-generica-vinos/bg-card-cabernet-sauvignon.jpg")})`,
      colorCardText: "#BABABA",
      bgColor: "#B98D67",
      borderColor: "#E0D1C4",
      bgImage: {
        url: `url(${require("../../assets/images/vista-generica-vinos/bg-img-cabernet-sauvignon.png")})`,
        size: "741.97px 477.86px",
        position: "top",
      },
      bottleshotUrl: require("../../assets/images/vista-generica-vinos/lm_syrah.png"),
      bannerUrl: {
        desktop: require("../../assets/images/vista-generica-vinos/banner-cabernet-sauvignon.jpg"),
        mobile: require("../../assets/images/vista-generica-vinos/banner-cabernet-sauvignon.jpg"),
      },
      composition: "100% Shiraz",
      tastingNotes: t("wine-card.shiraz.tasting-notes-text"),
      apellation: true,
      dots: require("../../assets/images/vineyards/puntitos.png"),
      parallax: true,
    },

    {
      name: "Cabernet Franc",
      id: "cabernet-franc",
      position: 2,
      type: "mascota",
      title: "La Mascota",
      titleColor: "#B98D67",
      bgCard: `url(${require("../../assets/images/vista-generica-vinos/bg-card-malbec.jpg")})`,
      colorCardText: "#BABABA",
      bgColor: "#B98D67",
      borderColor: "#707070",
      bgImage: {
        url: `url(${require("../../assets/images/vista-generica-vinos/bg-img-cabernet-sauvignon.png")})`,
        size: "741.97px 477.86px",
        position: "top",
      },
      bottleshotUrl: require("../../assets/images/vista-generica-vinos/lm_cabernet_f.png"),
      bannerUrl: {
        desktop: require("../../assets/images/vista-generica-vinos/banner-malbec.jpg"),
        mobile: require("../../assets/images/vista-generica-vinos/banner-malbec.jpg"),
      },
      composition: "100% Cabernet Franc",
      tastingNotes: t("wine-card.cabernet-franc.tasting-notes-text"),
      apellation: true,
      dots: require("../../assets/images/vista-generica-vinos/puntitos-light.png"),
      parallax: true,
    },

    {
      name: "Chardonnay",
      id: "chardonnay",
      position: 4,
      type: "mascota",
      title: "La Mascota",
      titleColor: "#D59570",
      bgCard: `url(${require("../../assets/images/vista-generica-vinos/bg-card-cabernet-sauvignon.jpg")})`,
      colorCardText: "#BABABA",
      bgColor: "#B98D67",
      borderColor: "#E0D1C4",
      bgImage: {
        url: `url(${require("../../assets/images/vista-generica-vinos/bg-img-cabernet-sauvignon.png")})`,
        size: "741.97px 477.86px",
        position: "top",
      },
      bottleshotUrl: require("../../assets/images/vista-generica-vinos/lm_chardonnay.png"),
      bannerUrl: {
        desktop: require("../../assets/images/vista-generica-vinos/banner-cabernet-sauvignon.jpg"),
        mobile: require("../../assets/images/vista-generica-vinos/banner-cabernet-sauvignon.jpg"),
      },
      composition: "100% Chardonnay",
      tastingNotes: t("wine-card.chardonnay.tasting-notes-text"),
      apellation: true,
      dots: require("../../assets/images/vineyards/puntitos.png"),
      parallax: true,
    },

    {
      name: "Sparkling",
      id: "sparkling",
      title: "La Mascota",
      titleColor: "#B98D67",
      bgCard: `url(${require("../../assets/images/vista-generica-vinos/bg-card-sparkling.jpg")})`,
      colorCardText: "#2D1E1A",
      bgColor: "#B98D67",
      bgImage: {
        url: `url(${require("../../assets/images/vista-generica-vinos/bg-img-cabernet-sauvignon.png")})`,
        size: "741.97px 477.86px",
        position: "top",
      },
      bottleshotUrl: require("../../assets/images/vista-generica-vinos/lm_sparkling.png"),
      bannerUrl: {
        desktop: require("../../assets/images/home/banners/slide_4.jpg"),
        mobile: require("../../assets/images/home/banners/slide_4_mobile.jpg"),
      },
      composition: "100% Pinot Noir",
      tastingNotes: t("wine-card.sparkling.tasting-notes-text"),
      dots: require("../../assets/images/vista-generica-vinos/puntitos-light.png"),
      parallax: false,
    },
  ];
  const getWine = (id) => {
    let wine = allWines.find((el) => el.id === id);
    setWine(wine);
  };
  return (
    <ContextWinesGlobal.Provider value={{ wine, getWine }}>
      {children}
    </ContextWinesGlobal.Provider>
  );
};

export default ContextWines;
