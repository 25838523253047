import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import ParallaxSection from "../../common/components/ParallaxSection";
import SectionWines from "../../common/components/SectionWines";

export default function Wines() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Mascota Vineyards - Wines</title>
        <link rel="canonical" href="https://mascotavineyards.com/wines" />
      </Helmet>
      <ParallaxSection
        urlBackgroundImgDesktop={require(`../../assets/images/section_wines/banner-our-wines.jpg`)}
        urlBackgroundImgMobile={require(`../../assets/images/section_wines/banner-our-wines-mobile.jpg`)}
        contentAling="flex-end"
        imageAlign="right"
      />
      <SectionWines />
    </>
  );
}
