export const menu2 = [
  {
    link: "/la-mascota/varietals",
    name: "La Mascota",
  },
  {
    link: "/unanime/varietals",
    name: "unánime",
  },
  {
    link: "/wines/unanime_signature",
    name: "unánime signature",
  },
  {
    link: "/wines/gran_mascota",
    name: "gran mascota",
  },
  {
    link: "/wines/magnanime",
    name: "magnánime",
  },
  {
    link: "/wines/la-mascota/sparkling",
    name: "sparkling",
  },
];
export const menu = [
  {
    link: "/wines",
    name: "wines",
    submenu: menu2,
  },
  {
    link: "/winemaker",
    name: "winemaker",
    submenu: null,
  },
  {
    link: "/vineyards",
    name: "vineyards",
    submenu: null,
  },
  {
    link: "/find_us",
    name: "find us",
    submenu: null,
  },
];
