import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    white: "#ffffff",
    red: "#791313",
    golden: "#B3995D",
    goldenLight: "#D4B68E",
    black: "#000000",
    gray: "#212529",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1075,
      lg: 1280,
      xl: 1920,
    },
  },
});
theme.typography = {
  playfairRegular: {
    fontFamily: "Dis-Regular",
    color: "#FFFFFF",
    fontSize: "22px",
    [theme.breakpoints.up("md")]: {
      fontSize: "28px",
      lineHeight: "37px",
      textAlign: "center",
      letterSpacing: "0px",
    },
  },
  playfairBold: {
    fontFamily: "Dis-Bold",
    color: "#FFFFFF",
    [theme.breakpoints.up("md")]: {
      fontSize: "28px",
      lineHeight: "37px",
      textAlign: "center",
      letterSpacing: "0px",
    },
  },
  ralewayRegular: {
    fontFamily: "Rel-Regular",
    color: "#BABABA",
    [theme.breakpoints.up("md")]: {
      fontSize: "17px",
      lineHeight: "33px",
      textAlign: "center",
      letterSpacing: "0.85px",
    },
  },
  ralewayBold: {
    fontFamily: "Rel-Bold",
    color: "#FFFFFF",
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
      lineHeight: "24px",
      textAlign: "center",
      letterSpacing: "0.4px",
    },
  },
  titleBrow: {
    fontFamily: "Dis-Bold",
    color: "#2D1E1A",
    fontSize: "25px",
    [theme.breakpoints.up("md")]: {
      fontSize: "40px",
      lineHeight: "53px",
      textAlign: "left",
      letterSpacing: "0.8px",
      // maxWidth: 300,
    },
  },
  paragraphs: {
    fontFamily: "Whitney_Light",
    fontSize: "15px",
    lineHeight: "17px",
    letterSpacing: "0px",
    textAlign: "center",
    color: "#FFFFFF",
    fontWeight: "inherit",
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
      textAlign: "left",
      lineHeight: "35px",
      letterSpacing: 0,
    },
  },
};
theme.components = {
  MuiButton: {
    variants: [
      {
        props: { variant: "legal" },
        style: {
          display: "flex",
          flexDirection: "column",
          height: "64px",
          padding: 0,
          fontFamily: "Whitney_Book",
          fontSize: 18,
          textTransform: "uppercase",
          transition: "all 0.7s ease-in-out",
          color: theme.palette.white,
          border: "none",
          "& hr": {
            backgroundColor: theme.palette.red,
            width: "45px",
            opacity: 1,
            transition: "all 0.5s ease-in-out",
            height: "2px",
            border: "none",
          },
          "&:focus": {
            outline: "transparent",
          },
          "&:hover": {
            background: "transparent",
            "& hr": {
              backgroundColor: theme.palette.golden,
            },
          },
        },
      },
      {
        props: { variant: "arrow_more_info" },
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
          transition: "all 0.7s ease-in-out",
          background: "transparent",
          "&:focus": {
            outline: "transparent",
          },
          "&:hover": {
            background: "transparent",
            transform: "scale(1.2)",
          },
        },
      },
    ],
  },
  MuiTypography: {
    component: [
      {
        props: { component: "h1" },
        style: {
          fontSize: "50px",
          lineHeight: "78px",
          letterSpacing: "2px",
        },
      },
      {
        props: { component: "h2" },
        style: {
          fontSize: "44px",
          lineHeight: "51px",
          letterSpacing: "4.27px",
        },
      },
    ],
  },
  // MuiCssBaseline: {
  //   styleOverrides: `
  //     h1 {
  //       font-size: 50px !important;
  //       line-height: 78px !important;
  //       letter-spacing: 2px !important;
  //     }
  //     h2 {
  //       font-size: 39px !important;
  //       line-height: 62px !important;
  //       letter-spacing: 1.56px !important;
  //     }
  //     h3 {
  //       font-size: 30px
  //     }
  //     h4 {
  //       font-size:  25px
  //     }
  //   `,
  // },
};
theme.palette.home = {
  bgHeader:
    "linear-gradient(180deg, #4A3380 0%,  #B8314D 100%) 0% 0% no-repeat padding-box",
  bgcolor: "#FFFFFF",
  bgFooter:
    "linear-gradient(360deg, #4A3380 0%,  #B8314D 100%) 0% 0% no-repeat padding-box",
};

export { theme };
