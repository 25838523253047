import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import styled from "@emotion/styled";

const LinkCustom = styled(Link)(({ theme }) => ({
  textDecoration: "none !important",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&:hover": {
    textDecoration: "none !important",
  },
  [theme.breakpoints.up("md")]: {
    justifyContent: "flex-start",
  },
}));
const LinkInternal = ({ to, children }) => {
  return (
    <LinkCustom to={to} component={RouterLink}>
      {children}
    </LinkCustom>
  );
};

export default LinkInternal;
