import React from "react";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { theme } from "../../common/Theme/theme";
import { useTranslation } from "react-i18next";

import ZoomInHome from "./ZoomInHome";

const arrayImage = [
  {
    title: "La Mascota",
    image: require("../../assets/images/slide/vino-1.jpg"),
    url: "/la-mascota/varietals",
  },
  {
    title: "Unánime",
    image: require("../../assets/images/slide/vino-2.jpg"),
    url: "/unanime/varietals",
  },
  {
    title: "Unánime Signature",
    image: require("../../assets/images/slide/vino-3.jpg"),
    url: "/wines/unanime_signature",
  },
  {
    title: "Gran Mascota",
    image: require("../../assets/images/slide/vino-4.jpg"),
    url: "/wines/gran_mascota",
  },
  {
    title: "Magnánime",
    image: require("../../assets/images/slide/vino-5.jpg"),
    url: "/wines/magnanime",
  },
  {
    title: "Sparkling",
    image: require("../../assets/images/slide/vino-6.jpg"),
    url: "/wines/la-mascota/sparkling",
  },
];

const BackgroundOurWines = styled("div")({
  overflow: "hidden",
  display: "flex",
  position: "relative",
  background: `url(${require("../../assets/images/slide/fondo-grafito.jpg")})`,
  padding: "10px 0 50px",
  [theme.breakpoints.up("sm")]: {
    paddingBottom: "80px",
  },
  [theme.breakpoints.up("md")]: {
    width: "100%",
    backgroundImage: `url(${require("../../assets/images/home/fondo_our_wines.jpg")})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    paddingBottom: "120px",
  },
});

const SecondBackgroundOurWines = styled("div")({
  position: "absolute",
  top: "-30px",
  left: "0",
  width: "20%",
  height: "197px",
  background: `url(${require("../../assets/images/section_wines/wines_background.png")})`,
  backgroundRepeat: "no-repeat",
  [theme.breakpoints.up("sm")]: {
    width: "30%",
  },
  [theme.breakpoints.up("md")]: {
    width: "38%",
  },
});

const ContainerVinos = styled("div")({
  position: "relative",
  maxWidth: 1300,
  display: "flex",
  margin: "auto",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const TitleOurWines = styled("h1")({
  textTransform: "uppercase",
  fontFamily: "Dis-Bold",
  fontSize: 30,
  color: "#ffffff",
  letterSpacing: 3,
  marginBottom: 20,
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    letterSpacing: 0.8,
    lineHeight: "53px",
  },
});

const SubtitleOurWines = styled("h1")({
  fontFamily: "Rel-Regular",
  color: "#a1a1a1",
  fontSize: "15px",
  marginBottom: 10,
  width: "calc(100% - 60px)",
  textAlign: "center",
  letterSpacing: "0.00938em",
  lineHeight: 1.5,
  [theme.breakpoints.up("sm")]: {
    lineHeight: "34px",
    maxWidth: 650,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: 15,
    letterSpacing: "0.85px",
    textAlign: "center",
  },
});

const VinosZoom = styled(Grid)({
  margin: "5px 0",
  // [theme.breakpoints.up("sm")]: {
  //   margin: "15px 0",
  // },

  [theme.breakpoints.up("md")]: {
    margin: "10px 0",
  },
});
// const ImageFirma = styled("img")({
//   height: 45,
//   marginBottom: 40,
// });
const SectionWines = () => {
  const { t } = useTranslation();
  return (
    <BackgroundOurWines>
      <SecondBackgroundOurWines />
      <ContainerVinos>
        <TitleOurWines>{t("our-wines.title")}</TitleOurWines>
        <SubtitleOurWines>{t("our-wines.text")}</SubtitleOurWines>
        {/* <ImageFirma
          src={require(`../../assets/images/home/firma_rodolfo.png`)}
          alt="firma-rodolfo"
        /> */}
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="end"
          style={{ maxWidth: 1100, margin: "0 auto" }}
        >
          {arrayImage.map((element, index) => (
            <VinosZoom key={index} item xs={6} sm={6} md={4}>
              <ZoomInHome
                title={element.title}
                image={element.image}
                url={element.url}
              />
            </VinosZoom>
          ))}
        </Grid>
      </ContainerVinos>
    </BackgroundOurWines>
  );
};

export default SectionWines;
