// import styled from "@emotion/styled";
// import React from "react";

// const Root = styled("div")({
//   minHeight: "60vh",
//   backgroundImage: `url(${require("../../assets/images/slide/fondo-grafito.jpg")})`,
//   backgroundRepeat: "no-repeat",
//   backgroundSize: "cover",
// });

// const Home = () => {
//   return <Root></Root>;
// };

// export default Home;

import React, { useEffect, useState } from "react";
import cookie from "cookiejs";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
// import { useTranslation } from "react-i18next";
import { theme } from "../../common/Theme/theme";
import i18next from "i18next";
// import $ from "jquery";
import { gsap } from "gsap";
// import LinkExternal from "../../common/components/LinkExternal";

// const Backdrop = styled("div")({
//   position: "fixed",
//   width: "100%",
//   height: "100%",
//   backgroundColor: theme.palette.black,
//   top: 0,
//   left: 0,
//   zIndex: 9999,
// });

const Root = styled("div")({
  overflow: "scroll",
  overflowX: "hidden",
  backgroundImage: `url(${require("../../assets/images/legales/fondo-grafito.jpg")})`,
  backgroundColor: "#000000",
  position: "fixed",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  zIndex: 9999,
});
const Container = styled("div")({
  position: "relative",
  width: "100%",
  height: "100%",
});
const Content = styled("div")({
  position: "relative",
  zIndex: 10,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
});
const ContainerButtonLink = styled("div")({
  display: "flex",
});
const ButtonLink = styled("div")({
  display: "flex",
  flexDirection: "column",
  border: "2px solid #d59570",
  color: "#ffffff",
  fontSize: "15px",
  fontFamily: "Rel-Regular",
  letterSpacing: 0.4,
  lineHeight: "20px",
  padding: "10px 20px",
  textTransform: "uppercase",
  alignItems: "stretch",
  margin: "0 15px",
  marginBottom: "clamp(18px,2vw,27px)",
  textAlign: "center",
  cursor: "pointer",
  transition: "all 1s ease 0s",
  "&:hover": {
    backgroundColor: "#d59570",
    color: "#000000",
  },
});
// const ButtonLinkItem = styled("p")({
//   width: "100%",
//   margin: "0",
//   display: "flex",
//   alignItems: "center",
//   paddingBlock: "12px",
//   cursor: "pointer",
//   transition: "color .5s ease",
//   color: "#FFFFFF",
//   justifyContent: "center",

//   "&:hover": {
//     color: "#B18E6C",
//   },
// });
// const ButtonLinkHeader = styled("p")({
//   margin: "0",
//   display: "flex",
//   alignItems: "center",
//   paddingBlock: "12px",
//   cursor: "pointer",
//   color: "#FFFFFF",
//   justifyContent: "center",
// });
const Text = styled(Typography)({
  fontFamily: "Rel-Regular",
  color: "#a1a1a1",
  textAlign: "center",
  fontSize: "15px",
  lineHeight: "clamp(28px,2.5vw,34px)",
  letterSpacing: 1.08,
  marginBottom: "clamp(32px,3.3vw ,45px)",
  "& span": {
    fontFamily: "Dis-Italic",
  },
});
const Logo = styled("img")({
  position: "relative",
  marginBottom: "clamp(40px,6vw,80px)",
  width: "clamp(102px,10.4vw,142px)",
});

const DivContent = styled("div")({
  margin: "auto",
  padding: "0 30px",
  // marginTop: "clamp(20px,14vw, 155px)",
  // height: "100%",
  position: "relative",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  [theme.breakpoints.up("md")]: {
    height: "auto",
  },
});
const ContainText = styled("div")({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  top: "auto",
});

const Legal = () => {
  const [refCuadro, setRefCuadro] = useState(null);
  const [refRoot, setRefRoot] = useState(null);
  // const matches = useMediaQuery("(min-width:960px)");
  // const [open, setOpen] = useState(false);
  const [legal, setLegal] = useState(false);
  // const [loading, setLoading] = useState(false);
  useEffect(() => {
    // $(window).on("load", function () {});
    showLegacy();
  }, []);
  const showLegacy = () => {
    // setLoading(true);
    let legacy = cookie.get("legalErrazuriz");
    if (Boolean(legacy === "true")) {
      setLegal(false);
      return;
    } else {
      setLegal(true);
    }
  };
  const onClickAceptLegal = () => {
    setTimeout(() => {
      setLegal(false);
    }, 1000);

    cookie.set("legalErrazuriz", "true", { expires: 0.01 });
    animation();
  };
  async function handleClick(lang) {
    i18next.changeLanguage(lang);
  }
  const animation = () => {
    let timeLine = gsap.timeline({});
    timeLine.to([refCuadro], {
      ease: "circ.inOut",
      duration: 0.6,
      opacity: 0,
    });
    timeLine.to([refRoot], {
      ease: "circ.inOut",
      duration: 1,
      opacity: 0,
      zIndex: -1,
    });
  };
  return (
    <React.Fragment>
      {/* <Helmet>
        <link rel="preload" href="/locales/esp/translation.json" as="fetch" />
        {matches ? (
          <link
            rel="preload"
            href={require("../assets/images/legal/inicio_errazuriz.jpg")}
            as="image"
            type="image/jpg"
            crossorigin
          />
        ) : (
          <link
            rel="preload"
            href={require("../assets/images/legal/inicio_mobile_errazuriz.jpg")}
            as="image"
            type="image/jpg"
            crossorigin
          />
        )}
      </Helmet> */}
      {
        // !loading ? (
        // <Backdrop />
        // ) :
        legal ? (
          <Root ref={setRefRoot}>
            <Container>
              {/* <Image
              ref={setRefImage}
              src={
                matches
                  ? require("../../assets/images/slide/fondo-grafito.jpg")
                  : require("../../assets/images/slide/fondo-grafito.jpg")
              }
              alt="legal-portada"
              height={0}
            /> */}
              <Content ref={setRefCuadro}>
                <DivContent>
                  <Logo
                    src={require("../../assets/images/legales/logo_mascota.png")}
                    alt="logo"
                  />
                  <ContainText>
                    {/* <ContainerLang>
                  <ButtonLang
                    disableRipple={true}
                    disableFocusRipple
                    onClick={() => handleClick("esp")}
                  >
                    <TextLang
                      component="p"
                      style={{
                        fontWeight: t("language") === "esp" ? "bold" : "normal",
                      }}
                    >
                      ESP{" "}
                    </TextLang>
                  </ButtonLang>
                  <DividerLine />
                  <ButtonLang
                    disableRipple={true}
                    disableFocusRipple
                    // onClick={() => handleClick("eng")}
                  >
                    <TextLang
                      component="p"
                      style={{
                        fontWeight: t("language") === "eng" ? "bold" : "normal",
                      }}
                    >
                      {" "}
                      ENG
                    </TextLang>
                  </ButtonLang>
                </ContainerLang> */}
                    <Text>
                      {/* {t("legales.confirmacion_edad")} */}
                      Al entrar a este sitio acepta que usted posee la edad
                      legal para beber en su país. <br />
                      <span>
                        When you enter this site you agree that you have the
                        legal drinking age in your country
                      </span>
                    </Text>
                    {/* <Button variant="legal" onClick={onClickAceptLegal}>
                  {t("legales.entrar")}
                  <hr />
                </Button> */}
                    {/* <LinkExternal to={"https://mascotavineyards.com.ar"}>
                      <ButtonLink style={{ justifyContent: "center" }}>
                        Argentina
                      </ButtonLink>
                    </LinkExternal> */}
                    <ContainerButtonLink>
                      <ButtonLink
                        style={{ justifyContent: "center" }}
                        onClick={() => {
                          handleClick("es");
                          onClickAceptLegal();
                        }}
                      >
                        Español
                      </ButtonLink>
                      <ButtonLink
                        style={{ justifyContent: "center" }}
                        onClick={() => {
                          handleClick("en");
                          onClickAceptLegal();
                        }}
                      >
                        English
                      </ButtonLink>
                    </ContainerButtonLink>

                    {/* <ButtonLink
                      style={{ marginBottom: 0 }}
                      onClick={() => setOpen((prevOpen) => !prevOpen)}
                    >
                      <ButtonLinkHeader>
                        {" "}
                        Resto del Mundo{" "}
                        {open ? (
                          <svg
                            style={{ marginLeft: "10px" }}
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-chevron-up"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#ffffff"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <polyline points="6 15 12 9 18 15" />
                          </svg>
                        ) : (
                          <svg
                            style={{ marginLeft: "10px" }}
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-chevron-down"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#ffffff"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <polyline points="6 9 12 15 18 9" />
                          </svg>
                        )}
                      </ButtonLinkHeader>
                    </ButtonLink>
                    <ButtonLink
                      className="box-languages"
                      style={{
                        overflow: "hidden",
                        transition: "height .7s",
                        borderTop: "none",
                        borderBottom: !open && "transparent",
                        height: open
                          ? document.querySelector(".box-languages")
                              .scrollHeight
                          : 0,
                      }}
                    >
                      <ButtonLinkItem
                        onClick={() => {
                          handleClick("eng");
                          onClickAceptLegal();
                        }}
                      >
                        English
                      </ButtonLinkItem>
                      <ButtonLinkItem
                        onClick={() => {
                          handleClick("esp");
                          onClickAceptLegal();
                        }}
                      >
                        Español
                      </ButtonLinkItem>
                    </ButtonLink> */}
                  </ContainText>
                </DivContent>
              </Content>
            </Container>
          </Root>
        ) : (
          <React.Fragment></React.Fragment>
        )
      }
    </React.Fragment>
  );
};

export default Legal;
